import React from 'react'
import cover from '../../data/cover2.png'

const Body = () => {
  return (
      <div className='min-h-screen bg-d-bg-100'>
          
          <div>
              <img src={cover} alt="" />
          </div>
          
    </div>
  )
}

export default Body